import { defineNuxtPlugin, useRoute, useRuntimeConfig } from '#imports'

import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useSessionId } from '@backmarket/nuxt-module-identification/useSessionId'
import { useVisitorId } from '@backmarket/nuxt-module-identification/useVisitorId'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import {
  init as initAmplitude,
  setUserProperties as setAmplitudeUserProperties,
  setOptIn as setOptinAmplitude,
} from '../amplitude'
import { USER_GROUP_NAME, USER_GROUP_VALUES } from '../amplitude/constants'
import { init as initBraze, setOptIn as setOptInBraze } from '../braze'
import { useDataConsent } from '../composables/useDataConsent'
import { useFeatureFlags } from '../composables/useFeatureFlags'
import { setContext, setUserContext } from '../context'
import { VENDORS_ID } from '../didomi/config'
import * as events from '../eventTrackers'
import { setOptIn as setOptinGoogleTagManager } from '../google-tag-manager'
import { initNoodle } from '../noodle'
import { getInitialContext } from '../utils/getInitialContext'
import { initWebVitals } from '../webVitals'

function initializeTracking() {
  const {
    public: { tracking: config },
  } = useRuntimeConfig()
  const { market, features } = useMarketplace()
  const visitorId = useVisitorId()
  const sessionId = useSessionId()
  const route = useRoute()

  const isE2ETestingEnvironment =
    window.navigator?.userAgent === 'MrFatUserAgent'

  // Routes specified in webVitals.allowedRouteNames will be tracking individually,
  // but the rest will be grouped under an "other" name, allowing us to track the whole app.
  const routeName =
    typeof route.name === 'string' &&
    config.webVitals.allowedRouteNames.includes(route.name)
      ? route.name
      : 'other'

  initWebVitals({
    routeName,
    country: market.countryCode,
  })

  /* Context setting */
  const initialContext = getInitialContext(
    market,
    features,
    useExperiments(),
    useFeatureFlags(),
    config,
    visitorId,
    sessionId,
    { isDidomiEnabled: true },
  )
  setContext(initialContext)

  /* Noodle */
  const shouldInitNoodle = config.noodle.isEnabled && !isE2ETestingEnvironment

  if (shouldInitNoodle) {
    const noodleUserContext = {
      country: market.countryCode,
      locale: market.defaultLocale,
      sessionId,
      visitorId,
    }

    const noodleConfig = {
      environment: config.environment,
      service: config.service,
      url: config.noodle.url,
      version: config.version,
    }

    initNoodle(noodleUserContext, noodleConfig)
  }

  // Automatic opt-in for in countries where the notice is not displayed
  if (
    config.countriesWithNoCookieOptIn.includes(market.countryCode) &&
    window.Didomi.shouldUserStatusBeCollected()
  ) {
    window.Didomi.setUserAgreeToAll()
  }

  const userStatus = window.Didomi.getCurrentUserStatus()

  /* Google Tag Manager */
  if (userStatus.vendors[VENDORS_ID.gtm]) {
    setOptinGoogleTagManager(userStatus.vendors[VENDORS_ID.gtm].enabled)

    window.Didomi.addVendorStatusListener(VENDORS_ID.gtm, ({ enabled }) => {
      setOptinGoogleTagManager(enabled)
    })
  }

  /* Amplitude */
  const shouldInitAmplitude =
    !isE2ETestingEnvironment &&
    config.amplitude.isEnabled &&
    config.amplitude.enabledCountryCodes.includes(market.countryCode) &&
    userStatus.vendors[VENDORS_ID.amplitude]

  if (shouldInitAmplitude) {
    // Identifying the user as not logged in initially
    setAmplitudeUserProperties({
      [USER_GROUP_NAME]: USER_GROUP_VALUES.NOT_LOGGED,
    })

    if (userStatus.vendors[VENDORS_ID.amplitude].enabled) {
      initAmplitude()
      setOptinAmplitude(true)
    }

    window.Didomi.addVendorStatusListener(
      VENDORS_ID.amplitude,
      ({ enabled }) => {
        if (enabled) {
          initAmplitude()
        }
        setOptinAmplitude(enabled)
      },
    )
  }

  /* Braze */
  const shouldInitBraze =
    !isE2ETestingEnvironment &&
    config.braze.isEnabled &&
    userStatus.vendors[VENDORS_ID.braze]

  if (shouldInitBraze) {
    void initBraze({
      apiKey: config.braze.apiKey,
      enabled: userStatus.vendors[VENDORS_ID.braze]?.enabled,
    })

    void setOptInBraze(userStatus.vendors[VENDORS_ID.braze]?.enabled)

    window.Didomi.addVendorStatusListener(VENDORS_ID.braze, ({ enabled }) => {
      void setOptInBraze(enabled)
    })
  }
}

export function createTrackingPlugin() {
  const { isDidomiEnabled } = useDataConsent()

  if (!isDidomiEnabled) return {}

  if (process.client) {
    window.didomiOnReady = window.didomiOnReady || []
    window.didomiOnReady.push(() => {
      initializeTracking()
    })
  }

  return {
    provide: {
      tracking: {
        ...events,
        setContext,
        setUserContext,
      },
    },
  }
}

export default defineNuxtPlugin(createTrackingPlugin)
